<template>
  <div id="ImportIBMDOORsNgModule">
    <b-row>
      <b-col cols="6">
        <b-card
          title="Module Selection"
          sub-title="Use the form below to select a Module in IBM DOORs"
        >
          <b-form>
            <!-- IBM DOORs Instance -->
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="IBM DOORs Instance"
                  label-for="ddlDoorsInstance"
                  label-cols-md="3"
                >
                  <v-select
                    v-model="selectedInstance"
                    :options="instances"
                    :clearable="false"
                    @input="fetchProjects"
                  />
                </b-form-group>
              </b-col>

              <!-- IBM DOORs Project -->
              <b-col cols="12">
                <b-form-group
                  label="Project"
                  label-for="ddlDoorsProject"
                  label-cols-md="3"
                >
                  <v-select
                    v-model="selectedProject"
                    label="title"
                    :options="projects"
                    :loading="loadingProjects"
                    :disabled="loadingProjects || !selectedInstance"
                    :clearable="false"
                    @input="fetchModules"
                  />
                </b-form-group>
              </b-col>

              <!-- IBM DOORs Module -->
              <b-col cols="12">
                <b-form-group
                  label="Module"
                  label-for="ddlDoorsModule"
                  label-cols-md="3"
                >
                  <v-select
                    v-model="selectedModule"
                    label="title"
                    :options="modules"
                    :loading="loadingModules"
                    :clearable="false"
                    :disabled="loadingModules || !selectedProject"
                  >
                    <template #option="{ identifier, title }">
                      <span>({{ identifier }}) {{ title }}</span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- Komp_ Specification Prefix -->
              <b-col cols="12">
                <b-form-group
                  label="Specification Prefix"
                  label-for="ddlPrefix"
                  label-cols-md="3"
                >
                  <b-input
                    v-model="selectedPrefix"
                    :loading="loadingModules"
                    placeholder="ABC-"
                    :clearable="true"
                    :disabled="loadingModules || !selectedProject || !selectedModule"
                  />
                </b-form-group>
              </b-col>

              <!-- Komp_ Specification Rename Option -->
              <b-col cols="12">
                <b-form-group
                  label="Specification Rename (Optional)"
                  label-for="ddlPrefix"
                  label-cols-md="3"
                >
                  <b-input
                    v-model="newSpecificationName"
                    :loading="loadingModules"
                    placeholder="Optionally change the Specification name"
                    :clearable="true"
                    :disabled="loadingModules || !selectedProject || !selectedModule"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>

      <b-col cols="6" class="d-flex">
        <b-card class="flex-grow-1 d-flex">
          <b-card-title>
            <span v-if="selectedModule && selectedModule.title">{{ selectedModule.title }} ({{ selectedModule.identifier }})</span>
            <span v-else>No module selected</span>
          </b-card-title>
          <b-card-sub-title v-if="selectedModule && selectedModule.title">
            <span>Project: {{ selectedProject.title }}</span>
          </b-card-sub-title>

          <b-card-text
            v-if="selectedModule && selectedModule.description"
            class="mt-2"
            v-sanitized-html="selectedModule.description"
          />

          <b-button-group class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              :disabled="loadingRequirements || !selectedModule"
              @click="fetchRequirements"
            >
              <b-spinner v-if="loadingRequirements" small />
              Preview Requirements
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              target="_blank"
              :disabled="!selectedModule"
              :href="selectedModule ? selectedModule.uri : ''"
            >
              Open in IBM DOORs
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-success"
              :disabled="!selectedModule || !selectedPrefix"
              size="sm"
              @click="importModule"
            >
              Import DOORs Module
            </b-button>
          </b-button-group>
          <div v-if="loadingImport" class="text-center mt-1">
            <b-spinner class="mt-3" variant="primary" />
            <br>
            <span>Processing...</span>
          </div>
          <div v-if="isComplete" class="text-center mt-1">
            <feather-icon icon="CheckCircleIcon" size="40" class="my-2 text-success" />
            <h4 class="text-success">
              Success
            </h4>
            <h5 class="text-success">
              Module successfully imported to Kompozition_
            </h5>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <!-- Card Element -->
        <b-card
          title="Requirements"
          no-body
        >
          <!-- Header -->
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">

              <!-- Sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="1"
                label-for="sortBySelect"
                label-class="mt-50 mr-50 pl-0"
                class="mb-0 text-nowrap"
                style="min-width: 20rem"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
              <!-- ./Sorting  -->

              <!-- Search -->
              <b-form-group
                label="Search"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                label-class="mt-50"
                class="mb-0"
                style="width: 30rem"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <!-- ./Search -->
            </div>
          </b-card-body>

          <!-- Table -->
          <b-table
            responsive
            striped
            hover
            show-empty
            class="position-relative"
            :fields="requirementFields"
            :items="requirements"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(title)="data">
              <span class="text-nowrap">{{ data.item.title }}</span>
            </template>

            <template #cell(section)="data">
              <span class="text-nowrap">{{ data.item.section }}</span>
            </template>

            <template #cell(primary_text)="data">
              <span v-sanitized-html="data.item.primary_text" />
            </template>
          </b-table>

          <!-- Footer -->
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- Page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-class="mt-50"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>
            <!-- ./Page length -->

            <!-- Pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="requirements.length"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <!-- ./Pagination -->
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  name: 'ImportIBMDOORsNgModule',
  directives: {
    Ripple,
  },
  components: {
    vSelect,
  },
  setup() {
    const instances = ref([])
    const selectedInstance = ref(null)
    const loadingInstances = ref(false)
    const fetchDoorsInstances = () => {
      instances.value = []
      loadingInstances.value = true
      axiosIns
        .get('/api/v2/doors/instances')
        .then(({ data }) => { instances.value = data })
        .catch(e => console.error(e))
        .finally(() => { loadingInstances.value = false })
    }
    fetchDoorsInstances()

    const projects = ref([])
    const selectedProject = ref(null)
    const loadingProjects = ref(false)
    const fetchProjects = () => {
      clearProjectsList()
      loadingProjects.value = true
      const headers = { 'Doors-Base-URL': selectedInstance.value.url }
      axiosIns
        .get('/api/v2/doors/projects', { headers })
        .then(({ data }) => { projects.value = data })
        .catch(e => console.error(e))
        .finally(() => { loadingProjects.value = false })
    }
    const clearProjectsList = () => {
      projects.value = []
      selectedProject.value = null
      clearModulesList()
    }

    const modules = ref([])
    const selectedModule = ref(null)
    const selectedPrefix = ref(null)
    const newSpecificationName = ref(null)
    const loadingModules = ref(false)
    const fetchModules = () => {
      if (selectedProject.value !== null) {
        clearModulesList()
        loadingModules.value = true
        const headers = { 'Doors-Base-URL': selectedInstance.value.url }
        axiosIns
          .get(`/api/v2/doors/${selectedProject.value.id}/modules`, { headers })
          .then(({ data }) => { modules.value = data })
          .catch(e => console.error(e))
          .finally(() => { loadingModules.value = false })
      }
    }
    const clearModulesList = () => {
      modules.value = []
      selectedModule.value = null
      selectedPrefix.value = null
      newSpecificationName.value = null
    }

    const requirements = ref([])
    const loadingRequirements = ref(false)
    const fetchRequirements = () => {
      if (selectedModule.value !== null) {
        clearRequirementsList()
        loadingRequirements.value = true
        const headers = { 'Doors-Base-URL': selectedInstance.value.url }
        axiosIns
          .get(`/api/v2/doors/modules/${selectedModule.value.id}/requirements`, { headers })
          .then(({ data }) => { requirements.value = data })
          .catch(e => console.error(e))
          .finally(() => { loadingRequirements.value = false })
      }
    }
    const clearRequirementsList = () => {
      requirements.value = []
    }

    const requirementFields = ref([
      {
        key: 'identifier',
        label: 'ID',
        sortable: true,
        thStyle: { width: '1%' },
        class: 'text-center text-nowrap',
      },
      {
        key: 'section',
        label: 'Section',
        sortable: true,
        thStyle: { width: '1%' },
        class: 'text-center text-nowrap',
      },
      {
        key: 'title',
        label: 'Title',
        sortable: true,
        thStyle: { width: '1%' },
        class: 'text-center text-nowrap',
      },
      {
        key: 'primary_text',
        label: 'Requirement Text',
        sortable: false,
      },
    ])

    // Pagination
    const perPage = ref(25)
    const pageOptions = ref([10, 25, 50, 100])
    const totalRows = ref(0)
    const currentPage = ref(1)
    const sortBy = ref('')
    const sortDesc = ref(false)
    const sortDirection = ref('asc')
    const filter = ref(null)
    const filterOn = ref([])
    const sortOptions = computed(() => requirementFields.value.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))
    const onFiltered = filteredItems => {
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const loadingImport = ref(false)
    const isComplete = ref(false)
    const importModule = () => {
      if (selectedModule.value !== null) {
        loadingImport.value = true
        isComplete.value = false
        const headers = { 'Doors-Base-URL': selectedInstance.value.url }
        const payload = {
          modelId: store.state.model.id,
          moduleUri: selectedModule.value.uri,
          action: 'create',
          specificationPrefix: selectedPrefix.value,
          newSpecificationName: newSpecificationName.value,
        }
        axiosIns
          .post(`/api/v2/doors/modules/${selectedModule.value.id}/import`, payload, { headers })
          .then(({ data }) => { console.log(data) })
          .catch(e => console.error(e))
          .finally(() => {
            loadingImport.value = false
            isComplete.value = true
          })
      }
    }

    return {
      instances,
      selectedInstance,

      projects,
      selectedProject,
      loadingProjects,
      fetchProjects,

      modules,
      selectedModule,
      selectedPrefix,
      newSpecificationName,
      loadingModules,
      fetchModules,

      requirements,
      loadingRequirements,
      fetchRequirements,
      requirementFields,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      sortOptions,
      onFiltered,

      loadingImport,
      isComplete,
      importModule,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
